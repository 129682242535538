@import '../../../scss/theme-bootstrap';

.gnav-offer-mobile-v1 {
  background: #fff;
  border-bottom: 1px solid #e3e4e5;
}

.gnav-offer-mobile-v1 .outer-wrap {
  overflow: hidden;
  padding: 30px 10px;
  position: relative;
}

.gnav-offer-mobile-v1 .gnav-offer-mobile-close {
  background: transparent url($img_base_path + '/icon-close.png') 0 0 no-repeat;
  cursor: pointer;
  height: 21px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 5px;
  width: 22px;
}

.gnav-offer-mobile-v1 .gnav-offer-mobile-image {
  float: #{$rdirection};
  height: auto;
  max-width: 120px;
}

.gnav-offer-mobile-v1 h2 {
  margin: 0;
  line-height: 1;
}

.gnav-offer-mobile-v1 .subheading {
  color: #000;
  font-size: 26px;
  line-height: 12px;
  padding: 8px 0;
}

.gnav-offer-mobile-v1 .subheading p {
  margin: 0;
}

.gnav-offer-mobile-v1 .gnav-offer-mobile-link {
  bottom: 15px;
  font-size: 13px;
  position: absolute;
}

@media screen and (max-width: 1024px) {
  .gnav-offer-mobile-v1 h2 {
    font-size: 26px;
    margin: 0;
  }
  .gnav-offer-mobile-v1 .subheading {
    font-size: 12px;
  }
  .gnav-offer-mobile-v1 .gnav-offer-mobile-close,
  .gnav-offer-mobile-v1 .gnav-offer-mobile-link {
    right: 20px;
  }
  .gnav-offer-mobile-v1 .gnav-offer-mobile-link {
    left: 10px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 420px) {
  bottom: 0;
}
